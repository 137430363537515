<!--
  PACKAGE_NAME : src\pages\esp\user\management\list.vue
  FILE_NAME : list
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 계정 관리
-->
<template>
  <div class="container">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <Tab title="활성계정 관리">
        <license-tool-tip ref="licenseToolTipRef" />
        <esp-dx-data-grid :data-grid="dataGrid.actAccount" ref="actAccountGrid"/>
      </Tab>
      <Tab title="잠금계정 관리">
        <license-tool-tip ref="licenseToolTipRef" />
        <esp-dx-data-grid :data-grid="dataGrid.lckAccount" ref="lckAccountGrid"/>
      </Tab>
      <Tab title="휴면계정 관리">
        <license-tool-tip ref="licenseToolTipRef" />
        <esp-dx-data-grid :data-grid="dataGrid.slpAccount" ref="slpAccountGrid"/>
      </Tab>
      <Tab title="삭제계정 관리">
        <license-tool-tip ref="licenseToolTipRef" />
        <esp-dx-data-grid :data-grid="dataGrid.delAccount" ref="delAccountGrid"/>
      </Tab>
    </Tabs>

    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
            :is="modal.currentComponent"
            :contentData="modal.contentData"
            v-model="modal.contentData"
          ></component>
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.hasOwnProperty('save')
              : !modal.initData.buttons.hasOwnProperty('save')
            : false
        "
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.text
              : ''
            : '',
          width: '120',
          height: '40',
          useSubmitBehavior: true,
          onClick: e => {
            onConfirmModal(e);
          },
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
  </div>
</template>

<script>
  import Tabs from '@/components/common/tabs.vue';
  import Tab from '@/components/common/tab.vue';
  import ModalActiveMember from '@/pages/esp/user/management/modal-active-member.vue';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxSwitch } from 'devextreme-vue/switch';
  import { DxPopover } from 'devextreme-vue/popover';
  import { isSuccess } from '@/plugins/common-lib';
  import LicenseToolTip from '@/components/common/license-tool-tip.vue';
  import CustomStore from "devextreme/data/custom_store";
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

  export default {
    components: {
      EspDxDataGrid,
      LicenseToolTip,
      DxPopover,
      Tabs,
      Tab,
      ModalActiveMember,
      DxPopup,
      DxToolbarItem,
      DxTextBox,
      DxButton,
      DxSelectBox,
      DxSwitch,
    },
    props: {},
    watch: {},
    data() {
      return {
        tabs: {
          selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path],
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        dataGrid: {
          //활성계정
          actAccount: {
            refName: 'actAccountRefName',
            allowColumnResizing: true, //컬럼 사이즈 허용
            showBorders: false, //border 유무
            showColumnHeaders: true, //컬럼 헤더 유무
            showColumnLines: false, //컬럼 세로선 유무
            showRowLines: true, //컬럼 가로선 유무
            rowAlternationEnabled: false,
            hoverStateEnabled: true,
            dataSource: [],
            showActionButtons: {
              // 그리드 버튼 노출 설정값
              select: false,
              update: false,
              customButtons: [
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '추가',
                    elementAttr: { class: 'btn_XS default filled add1' },
                    width: 60,
                    height: 30,
                    onClick: () => {
                      this.moveAddMemberPage();
                    },
                  },
                  location: 'before',
                },
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '삭제',
                    elementAttr: { class: 'btn_XS white light_filled trash' },
                    width: 60,
                    height: 30,
                    onClick: () => {
                      this.onDeleteMember();
                    },
                  },
                  location: 'before',
                },
              ],
            },
            grouping: {
              contextMenuEnabled: false,
              autoExpandAll: false,
              allowCollapsing: false,
              expandMode: 'rowClick', // rowClick or buttonClick
            },
            groupPanel: {
              visible: false,
            },
            columnChooser: {
              enabled: false, // 컬럼 Chooser 버튼 사용유무
            },
            loadPanel: {
              enabled: true, // 로딩바 표시 유무
            },
            sorting: {
              mode: 'multiple', // single multiple
            },
            remoteOperations: {
              filtering: false,
              sorting: true,
              grouping: false,
              paging: true,
            },
            paging: {
              // scrolling 미사용시만 적용됨
              enabled: true,
              pageSize: 10,
              pageIndex: 0, // 시작페이지
            },
            pager: {
              visible: true, //페이저 표시 여부
              showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
              allowedPageSizes: [], //페이지 사이즈 선택 박스
              displayMode: 'compact', //표시 모드 : ['full', 'compact']
              showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
              showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
            },
            filterRow: {
              visible: true,
            },
            headerFilter: {
              visible: true,
            },
            editing: {
              allowUpdating: false,
              allowDeleting: false,
              allowAdding: false,
              mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
              startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
              selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
            },
            selecting: {
              mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
              selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
              showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
            },
            columns: [
              {
                caption: '아이디',
                dataField: 'loginId',
                alignment: 'center', // left center right
                visible: true,
                sortOrder: 'none', // acs desc none
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'center', // left or right
              },
              {
                caption: '이름',
                dataField: 'loginNm',
                alignment: 'center', // left center right
                visible: true,
                sortOrder: 'none', // acs desc none
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'center', // left or right
                cellTemplate: (container, options) => {
                  let aTag = document.createElement('a');
                  aTag.innerText = options.value;
                  aTag.addEventListener('click', () => {
                    this.onUpdateData(options.data);
                  });
                  container.append(aTag);
                },
              },
              {
                caption: '계정 권한',
                dataField: 'authNm',
                alignment: 'center', // left center right
                visible: true,
                sortOrder: 'none', // acs desc none
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'center', // left or right
                calculateCellValue: rowData => {
                  if (rowData.authNm === null) {
                    return '권한 없음';
                  }
                  return rowData.authNm;
                },
              },
              {
                caption: '등록자',
                dataField: 'regId',
                alignment: 'center',
                visible: true,
                sortOrder: 'none',
                allowHeaderFiltering: false,
              },
              {
                dataType: 'date',
                dataField: 'regDt',
                format: 'yyyy-MM-dd HH:mm:ss',
                caption: '등록일시',
                alignment: 'center',
                visible: true,
                sortOrder: 'none',
                allowHeaderFiltering: false,
              },
            ],
          },
          //잠금계정
          lckAccount: {
            refName: 'lckAccountRefName',
            allowColumnResizing: true, //컬럼 사이즈 허용
            showBorders: false, //border 유무
            showColumnHeaders: true, //컬럼 헤더 유무
            showColumnLines: false, //컬럼 세로선 유무
            showRowLines: true, //컬럼 가로선 유무
            rowAlternationEnabled: false,
            hoverStateEnabled: true,
            dataSource: [],
            showActionButtons: {
              // 그리드 버튼 노출 설정값
              select: false,
              update: false,
              customButtons: [
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '계정 활성화',
                    elementAttr: { class: 'btn_XS white light_filled' },
                    height: 30,
                    onClick: () => {
                      this.onActiveMemberByLck();
                    },
                  },
                  location: 'before',
                },
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '삭제',
                    elementAttr: { class: 'btn_XS white light_filled trash' },
                    width: 60,
                    height: 30,
                    onClick: () => {
                      this.onDeleteMember();
                    },
                  },
                  location: 'before',
                },
              ],
            },
            grouping: {
              contextMenuEnabled: false,
              autoExpandAll: false,
              allowCollapsing: false,
              expandMode: 'rowClick', // rowClick or buttonClick
            },
            groupPanel: {
              visible: false,
            },
            columnChooser: {
              enabled: false, // 컬럼 Chooser 버튼 사용유무
            },
            loadPanel: {
              enabled: true, // 로딩바 표시 유무
            },
            sorting: {
              mode: 'multiple', // single multiple
            },
            remoteOperations: {
              filtering: false,
              sorting: true,
              grouping: false,
              paging: true,
            },
            paging: {
              // scrolling 미사용시만 적용됨
              enabled: true,
              pageSize: 10,
              pageIndex: 0, // 시작페이지
            },
            pager: {
              visible: true, //페이저 표시 여부
              showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
              allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
              displayMode: 'compact', //표시 모드 : ['full', 'compact']
              showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
              showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
            },
            filterRow: {
              visible: true,
            },
            headerFilter: {
              visible: true,
            },
            editing: {
              allowUpdating: false,
              allowDeleting: false,
              allowAdding: false,
              mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
              startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
              selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
            },
            selecting: {
              mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
              selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
              showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
            },
            columns: [
              {
                caption: '아이디',
                dataField: 'loginId',
                alignment: 'center', // left center right
                visible: true,
                sortOrder: 'none', // acs desc none
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'center', // left or right
              },
              {
                caption: '이름',
                dataField: 'loginNm',
                alignment: 'center', // left center right
                visible: true,
                sortOrder: 'none', // acs desc none
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'center', // left or right
              },
              {
                caption: '계정 권한',
                dataField: 'authNm',
                alignment: 'center', // left center right
                visible: true,
                sortOrder: 'none', // acs desc none
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'center', // left or right
                calculateCellValue: rowData => {
                  if (rowData.authNm === null) {
                    return '권한 없음';
                  }
                  return rowData.authNm;
                },
              },
              {
                caption: '수정자',
                dataField: 'editId',
                alignment: 'center',
                visible: true,
                sortOrder: 'none',
                allowHeaderFiltering: false,
              },
              {
                caption: '수정 일시',
                dataType: 'date',
                dataField: 'editDt',
                format: 'yyyy-MM-dd HH:mm:ss',
                alignment: 'center',
                visible: true,
                sortOrder: 'none',
                allowHeaderFiltering: false,
              },
            ],
          },
          //휴면계정
          slpAccount: {
            refName: 'slpAccountRefName',
            allowColumnResizing: true, //컬럼 사이즈 허용
            showBorders: false, //border 유무
            showColumnHeaders: true, //컬럼 헤더 유무
            showColumnLines: false, //컬럼 세로선 유무
            showRowLines: true, //컬럼 가로선 유무
            rowAlternationEnabled: false,
            hoverStateEnabled: true,
            dataSource: [],
            showActionButtons: {
              // 그리드 버튼 노출 설정값
              select: false,
              update: false,
              customButtons: [
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '계정 활성화',
                    elementAttr: { class: 'btn_XS white light_filled' },
                    height: 30,
                    onClick: () => {
                      this.onChangedActiveState();
                    },
                  },
                  location: 'before',
                },
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '삭제',
                    elementAttr: { class: 'btn_XS white light_filled trash' },
                    width: 60,
                    height: 30,
                    onClick: () => {
                      this.onDeleteMember();
                    },
                  },
                  location: 'before',
                },
              ],
            },
            grouping: {
              contextMenuEnabled: false,
              autoExpandAll: false,
              allowCollapsing: false,
              expandMode: 'rowClick', // rowClick or buttonClick
            },
            groupPanel: {
              visible: false,
            },
            columnChooser: {
              enabled: false, // 컬럼 Chooser 버튼 사용유무
            },
            loadPanel: {
              enabled: true, // 로딩바 표시 유무
            },
            sorting: {
              mode: 'multiple', // single multiple
            },
            remoteOperations: {
              filtering: false,
              sorting: true,
              grouping: false,
              paging: true,
            },
            paging: {
              // scrolling 미사용시만 적용됨
              enabled: true,
              pageSize: 10,
              pageIndex: 0, // 시작페이지
            },
            pager: {
              visible: true, //페이저 표시 여부
              showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
              allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
              displayMode: 'compact', //표시 모드 : ['full', 'compact']
              showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
              showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
            },
            filterRow: {
              visible: true,
            },
            headerFilter: {
              visible: true,
            },
            editing: {
              allowUpdating: false,
              allowDeleting: false,
              allowAdding: false,
              mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
              startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
              selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
            },
            selecting: {
              mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
              selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
              showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
            },
            columns: [
              {
                caption: '아이디',
                dataField: 'loginId',
                alignment: 'center', // left center right
                visible: true,
                sortOrder: 'none', // acs desc none
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'center', // left or right
              },
              {
                caption: '이름',
                dataField: 'loginNm',
                alignment: 'center', // left center right
                visible: true,
                sortOrder: 'none', // acs desc none
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'center', // left or right
              },
              {
                caption: '계정 권한',
                dataField: 'authNm',
                alignment: 'center', // left center right
                visible: true,
                sortOrder: 'none', // acs desc none
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'center', // left or right
                calculateCellValue: rowData => {
                  if (rowData.authNm === null) {
                    return '권한 없음';
                  }
                  return rowData.authNm;
                },
              },
              {
                caption: '수정자',
                dataField: 'editId',
                alignment: 'center',
                visible: true,
                sortOrder: 'none',
                allowHeaderFiltering: false,
              },
              {
                caption: '수정 일시',
                dataType: 'date',
                dataField: 'editDt',
                format: 'yyyy-MM-dd HH:mm:ss',
                alignment: 'center',
                visible: true,
                sortOrder: 'none',
                allowHeaderFiltering: false,
              },
            ],
          },
          //삭제계정
          delAccount: {
            refName: 'delAccountRefName',
            allowColumnResizing: true, //컬럼 사이즈 허용
            showBorders: false, //border 유무
            showColumnHeaders: true, //컬럼 헤더 유무
            showColumnLines: false, //컬럼 세로선 유무
            showRowLines: true, //컬럼 가로선 유무
            rowAlternationEnabled: false,
            hoverStateEnabled: true,
            dataSource: [],
            showActionButtons: {
              // 그리드 버튼 노출 설정값
              select: false,
              update: false,
              customButtons: [
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '계정 활성화',
                    elementAttr: { class: 'btn_XS white light_filled' },
                    height: 30,
                    onClick: () => {
                      this.onChangedActiveState();
                    },
                  },
                  location: 'before',
                },
              ],
            },
            grouping: {
              contextMenuEnabled: false,
              autoExpandAll: false,
              allowCollapsing: false,
              expandMode: 'rowClick', // rowClick or buttonClick
            },
            groupPanel: {
              visible: false,
            },
            columnChooser: {
              enabled: false, // 컬럼 Chooser 버튼 사용유무
            },
            loadPanel: {
              enabled: true, // 로딩바 표시 유무
            },
            sorting: {
              mode: 'multiple', // single multiple
            },
            remoteOperations: {
              filtering: false,
              sorting: true,
              grouping: false,
              paging: true,
            },
            paging: {
              // scrolling 미사용시만 적용됨
              enabled: true,
              pageSize: 10,
              pageIndex: 0, // 시작페이지
            },
            pager: {
              visible: true, //페이저 표시 여부
              showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
              allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
              displayMode: 'compact', //표시 모드 : ['full', 'compact']
              showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
              showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
            },
            filterRow: {
              visible: true,
            },
            headerFilter: {
              visible: true,
            },
            editing: {
              allowUpdating: false,
              allowDeleting: false,
              allowAdding: false,
              mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
              startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
              selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
            },
            selecting: {
              mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
              selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
              showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
            },
            columns: [
              {
                caption: '아이디',
                dataField: 'loginId',
                alignment: 'center', // left center right
                visible: true,
                sortOrder: 'none', // acs desc none
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'center', // left or right
              },
              {
                caption: '이름',
                dataField: 'loginNm',
                alignment: 'center', // left center right
                visible: true,
                sortOrder: 'none', // acs desc none
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'center', // left or right
              },
              {
                caption: '계정 권한',
                dataField: 'authNm',
                alignment: 'center', // left center right
                visible: true,
                sortOrder: 'none', // acs desc none
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'center', // left or right
                calculateCellValue: rowData => {
                  if (rowData.authNm === null) {
                    return '권한 없음';
                  }
                  return rowData.authNm;
                },
              },
              {
                caption: '수정자',
                dataField: 'editId',
                alignment: 'center',
                visible: true,
                sortOrder: 'none',
                allowHeaderFiltering: false,
              },
              {
                caption: '수정 일시',
                dataType: 'date',
                dataField: 'editDt',
                format: 'yyyy-MM-dd HH:mm:ss',
                alignment: 'center',
                visible: true,
                sortOrder: 'none',
                allowHeaderFiltering: false,
              },
            ],
          },
        },
      };
    },
    computed: {
      /** @description: 탭 별 선택된 데이터 가져오기 */
      getSelectedRowsDataByTab() {
        let selectedRowsData = [];
        if (this.tabs.selectedIndex === 0) {
          // 활성계정 관리
          selectedRowsData = this.$refs.actAccountGrid.selectedRowsData;
        } else if (this.tabs.selectedIndex === 1) {
          // 잠금계정 관리
          selectedRowsData = this.$refs.lckAccountGrid.selectedRowsData;
        } else if (this.tabs.selectedIndex === 2) {
          // 휴면계정 관리
          selectedRowsData = this.$refs.slpAccountGrid.selectedRowsData;
        } else if (this.tabs.selectedIndex === 3) {
          // 삭제계정 관리
          selectedRowsData = this.$refs.delAccountGrid.selectedRowsData;
        }
        return selectedRowsData;
      },
    },
    methods: {
      /** @description: 선택된 탭 index */
      async tabSelectedIndex(index) {
        this.tabs.selectedIndex = index;
        if (this.tabs.selectedIndex === 0) {
          this.dataGrid.actAccount.dataSource = this.makeDataSource(
            `${this.$_enums.common.memberState.ACTIVE.value},${this.$_enums.common.memberState.TEMPORARY.value}`,
            '-regDt',
          );
        } else if (this.tabs.selectedIndex === 1) {
          this.dataGrid.lckAccount.dataSource = this.makeDataSource(
            this.$_enums.common.memberState.LOCKED.value,
            '-editDt',
          );
        } else if (this.tabs.selectedIndex === 2) {
          this.dataGrid.slpAccount.dataSource = this.makeDataSource(
            this.$_enums.common.memberState.DORMANT.value,
            '-editDt',
          );
        } else if (this.tabs.selectedIndex === 3) {
          this.dataGrid.delAccount.dataSource = this.makeDataSource(
            this.$_enums.common.memberState.DELETED.value,
            '-editDt',
          );
        }
      },
      /** @description: 팝업 오픈시 메서드 */
      onOpenModal(componentNm, componentInitData, data) {
        this.modal.currentComponent = componentNm;
        this.modal.initData = componentInitData;
        this.modal.contentData = data;
        this.isOpenModal(true);
      },
      /** @description: 팝업 오픈 체크 메서드 */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      /** @description: 팝업 확인 버튼 이벤트 */
      async onConfirmModal(e) {
        //해당 모달 컴포넌트에서 데이터 저장
        let promise = new Promise((resolve, reject) => {
          this.$_eventbus.$emit(`${this.modal.currentComponent}:onSaveData`, e, resolve, reject);
        });

        promise
          .then(res => {
            // use res
            if (res === 200) {
              this.isOpenModal(false);
              this.refreshMemberGridList();
            } else {
              this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          })
          .catch(err => {
            // handle error
            this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          });
      },
      /** @description" 계정 리스트 grid refresh 이벤트 */
      async refreshMemberGridList() {
        if (this.tabs.selectedIndex === 0) {
          // 활성계정 관리
          this.$refs.actAccountGrid.refreshData();
          this.$refs.actAccountGrid.getGridInstance.deselectAll(); //선택 해제
        } else if (this.tabs.selectedIndex === 1) {
          // 잠금계정 관리
          this.$refs.lckAccountGrid.refreshData();
          this.$refs.lckAccountGrid.getGridInstance.deselectAll(); //선택 해제
        } else if (this.tabs.selectedIndex === 2) {
          // 휴면계정 관리
          this.$refs.slpAccountGrid.refreshData();
          this.$refs.slpAccountGrid.getGridInstance.deselectAll(); //선택 해제
        } else if (this.tabs.selectedIndex === 3) {
          // 삭제계정 관리
          this.$refs.delAccountGrid.refreshData();
          this.$refs.delAccountGrid.getGridInstance.deselectAll(); //선택 해제
        }
        //유저 라이센스 개수 가져오기
        this.memberLicenseCnt = await this.$_getMemberLicenseCnt();
      },
      /** @description: 활성계정관리 리스트 조회 API 호출 메서드 */
      makeDataSource(memberStateCd, sortStr) {
        const vm = this;
        return new CustomStore({
          key: 'id',
          async load(loadOptions) {
            const params = vm.$_getDxDataGridParam(loadOptions);
            const payload = {
              actionname: 'MEMBER_LIST_ALL',
              data: {
                ...params,
                sort: sortStr,
                memberStateCd: memberStateCd,
              },
              loading: false,
            };

            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              const data = res.data.data;
              return {
                data: data,
                totalCount: res.data.header.totalCount,
              };
            }
          },
        });
      },
      /** @description: 계정 활성화 일괄 변경 이벤트  */
      async onChangedActiveState() {
        if (this.getSelectedRowsDataByTab.length === 0) {
          return this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }

        //계정 라이센스 수 체크
        if (this.getSelectedRowsDataByTab.length > (await this.getLeftLicenseCnt())) {
          return this.$_Msg(
            this.$_msgContents('OVER_LICENSE_COUNT', {
              defaultValue: '등록 가능한 라이센스 수를 초과하여<br/>계정을 더 이상 등록할 수 없습니다.',
            }),
          );
        }

        if (
          await this.$_Confirm(
            this.$_msgContents('ACTIVATE_SELECTED_ACCOUNT', { defaultValue: '선택한 계정을 활성화하시겠습니까?' }),
          )
        ) {
          await this.updateActiveState();
        } else {
          return false;
        }
      },
      /** @description: 계정 상태 "활성"으로 변경 */
      async updateActiveState() {
        const payload = {
          actionname: 'MEMBER_STATE_ACTIVATION',
          data: {
            activeMembers: this.getSelectedRowsDataByTab.map(item => {
              return {
                memberId: item.id,
                loginPwd: '',
              };
            }),
          },
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('CMN_SUC_UPDATE', { defaultValue: '정상적으로 변경되었습니다.' }));
          await this.refreshMemberGridList();
        }
      },
      /** @description: 잠금계정관리 계정 활성화 메서드 */
      async onActiveMemberByLck() {
        if (this.getSelectedRowsDataByTab.length === 0) {
          return this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }

        // 계정 라이센스 수 체크
        if (this.getSelectedRowsDataByTab.length > (await this.getLeftLicenseCnt())) {
          return this.$_Msg(
            this.$_msgContents('OVER_LICENSE_COUNT', {
              defaultValue: '등록 가능한 라이센스 수를 초과하여<br/>계정을 더 이상 등록할 수 없습니다.',
            }),
          );
        }

        this.onOpenModal(
          'ModalActiveMember',
          {
            title: '계정 활성화',
            buttons: {
              save: { text: '확인' },
              cancel: { text: '취소' },
            },
            width: 550,
            height: 450,
          },
          {
            selectedRowsData: this.getSelectedRowsDataByTab,
            state: 'LCK',
          },
        );
      },
      /** @description: 계정관리 삭제 메서드 */
      async onDeleteMember() {
        if (this.getSelectedRowsDataByTab.length === 0) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (
          await this.$_Confirm(
            this.$_msgContents('CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }),
          )
        ) {
          const params = this.getSelectedRowsDataByTab.map(item => {
            return {
              id: item.id,
              authId: item.authId,
              loginId: item.loginId,
              loginNm: item.loginNm,
              loginPwd: item.loginPwd,
              memberState: this.$_enums.common.memberState.DELETED.value,
            };
          });

          const payload = {
            actionname: 'MEMBER_LIST_INSERT',
            data: params,
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('CMN_SUC_DELETE', { defaultValue: '선택한 데이터가 삭제되었습니다.' }));
            await this.refreshMemberGridList();
          }
        }
      },
      /** @description : 계정 추가 페이지 이동 */
      moveAddMemberPage() {
        if (this.tabs.selectedIndex === 0) {
          if (this.$refs.licenseToolTipRef.isLicenseAvailable()) {
            let params = { updateYn: false };
            this.$store.commit('setDetailParams', params);
            this.$router.push('/esp/user/management/config');
          } else {
            this.$_Msg(
              this.$_msgContents('OVER_LICENSE_COUNT', {
                defaultValue: '등록 가능한 라이센스 수를 초과하여<br/>계정을 더 이상 등록할 수 없습니다.',
              }),
            );
          }
        }
      },
      /** @description : 계정 수정 페이지 이동 */
      onUpdateData(data) {
        let params = { id: data.id, authId: data.authId, loginId: data.loginId, updateYn: true };
        this.$store.commit('setDetailParams', params);
        this.$router.push('/esp/user/management/config');
      },
      /**
       * @description 남은 라이센스 개수
       * @return {Promise<T>}
       */
      getLeftLicenseCnt() {
        return this.$refs.licenseToolTipRef.leftLicenseCnt().then(
          res => {
            return res;
          },
          err => {
            this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          },
        );
      },
    },
    created() {},
    mounted() {},
  };
</script>

<style scoped></style>
