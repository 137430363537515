<!--
  PACKAGE_NAME : src\pages\esp\user\management\modal-active-member.vue
  FILE_NAME : modal-active-member
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 계정 활성화 모달
-->
<template>
  <transition>
    <div ref="contentsWrap" class="contents-wrap" id="contentsWrap">
      <div class="contents-box">
        <table class="table_form line-bin">
          <caption>
            <strong>계정 활성화</strong>
          </caption>
          <colgroup>
            <col style="width: 160px" />
            <col style="width: auto" />
          </colgroup>

          <thead class="sub_title_txt"></thead>

          <tbody>
            <tr>
              <th scope="row">
                <label for="label01"> 처리 구분 </label>
              </th>
              <td>
                <DxRadioGroup
                  class="check-type col"
                  :items="config.processType.items"
                  :value="getProcessType"
                  :layout="config.processType.layout"
                  @value-changed="onChangedProcessType"
                />
              </td>
            </tr>
            <tr v-if="getProcessTypeId === 0">
              <th scope="row">
                <label for="label01">
                  비밀번호 변경 유형
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td>
                <DxSelectBox
                  v-model="formData.tempPwdFl"
                  placeholder="비밀번호 변경 유형"
                  :items="config.tempPwdFls"
                  display-expr="title"
                  value-expr="value"
                  @value-changed="onChangedTempPwdFl"
                  :styling-mode="config.stylingMode"
                  width="90%"
                  :height="30"
                >
                  <DxValidator>
                    <DxRequiredRule message="비밀번호 변경 유형은 필수값입니다." />
                  </DxValidator>
                </DxSelectBox>
                <button
                  class="btn-question"
                  id="tooltips1"
                  @mouseenter="setTooltips('changePwdType')"
                  @mouseleave="setTooltips('changePwdType')"
                ></button>
                <DxPopover
                  target="#tooltips1"
                  :visible="config.isTooltip.changePwdType"
                  :close-on-outside-click="false"
                >
                  <span>
                    ‘새 비밀번호’는 사용자가 수정된 비밀번호를 계속 사용하도록 허용합니다.<br />
                    ‘1회용 임시 비밀번호’는 사용자의 로그인 직후 비밀번호 변경이 반드시 필요합니다.
                  </span>
                </DxPopover>
              </td>
            </tr>
            <tr v-if="getProcessTypeId === 0">
              <th scope="row">
                <label for="label01">
                  비밀번호
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td>
                <DxTextBox
                  v-model="formData.loginPwd"
                  placeholder="비밀번호"
                  mode="password"
                  :disabled="formData.isDisabledPwd"
                  :show-clear-button="true"
                  :max-length="limitNumberTexts.maxLengths.loginPwd"
                  :styling-mode="config.stylingMode"
                  width="90%"
                  :height="30"
                  class="mar_ri10"
                  style="vertical-align: middle"
                >
                  <DxValidator>
                    <DxRequiredRule message="비밀번호는 필수값입니다." />
                  </DxValidator>
                </DxTextBox>
              </td>
            </tr>
            <tr v-if="getProcessTypeId === 0">
              <th scope="row">
                <label for="label01">
                  비밀번호 확인
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td>
                <DxTextBox
                  v-model="formData.loginPwdConfirm"
                  placeholder="비밀번호"
                  mode="password"
                  :disabled="formData.isDisabledPwd"
                  :show-clear-button="true"
                  :max-length="limitNumberTexts.maxLengths.loginPwdConfirm"
                  :styling-mode="config.stylingMode"
                  width="90%"
                  :height="30"
                  class="mar_ri10"
                  style="vertical-align: middle"
                >
                  <DxValidator>
                    <DxRequiredRule message="비밀번호 확인은 필수값입니다." />
                    <DxCompareRule :comparison-target="passwordComparison" message="비밀번호값과 일치하지 않습니다." />
                  </DxValidator>
                </DxTextBox>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="container-bottom-box">
        <template v-if="getProcessTypeId === 0">
          선택한 <span class="length-txt">{{ contentData.selectedRowsData.length }}</span
          >개 계정의 비밀번호를 변경하고, 계정을 활성화하시겠습니까?<br />
          (비밀번호 변경 시, 비밀번호 오류 회수는 초기화됩니다.)
        </template>
        <template v-else>
          선택한 <span class="length-txt">{{ contentData.selectedRowsData.length }}</span
          >개 계정의 비밀번호는 유지하고 계정은 활성화하시겠습니까?
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxValidator, DxRequiredRule, DxCompareRule } from 'devextreme-vue/validator';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import DxRadioGroup from 'devextreme-vue/radio-group';
  import { DxPopover } from 'devextreme-vue/popover';
  import { isSuccess, getRegexPattern, encryptPassword } from '@/plugins/common-lib';

  export default {
    components: {
      DxTextBox,
      DxValidator,
      DxRequiredRule,
      DxCompareRule,
      DxSelectBox,
      DxRadioGroup,
      DxPopover,
    },
    props: {
      contentData: Object,
      iconData: Array,
    },
    watch: {},
    data() {
      return {
        config: {
          stylingMode: 'outlined', //[outlined, filled, underlined]
          processType: {
            layout: 'horizontal',
            items: [
              { id: 0, text: '비밀번호 변경 + 계정 활성화' },
              { id: 1, text: '계정 활성화' },
            ],
            value: 0,
          },
          isTooltip: {
            //tooltip 관련
            changePwdType: false,
          },
          tempPwdFls: [
            //비밀번호 변경 유형 여부
            {
              title: '새 비밀번호',
              value: 0,
            },
            {
              title: '1회용 임시 비밀번호',
              value: 2,
            },
          ],
          passwordReg: '', //비밀번호 정책 관련 config
          passwordRegMsg: '', //비밀번호 정책 관련 description
        },
        formData: {
          tempPwdFl: null,
          loginPwd: '',
          loginPwdConfirm: '',
          loginPwdDefault: '1234',
          changedPwdFl: true,
          isDisabledPwd: true,
        },
        limitNumberTexts: {
          textLengths: {},
          maxLengths: {
            loginPwd: 50,
            loginPwdConfirm: 50,
          },
        },
      };
    },
    computed: {
      /** @description: 처리구분 선택된 데이터 */
      getProcessType() {
        return this.config.processType.items.find(d => d.id === this.config.processType.value);
      },
      /** @description: 처리구분 Radio value */
      getProcessTypeId() {
        return this.getProcessType.id;
      },
      /** @description : 시스템 설정의 default_pwd 가져오기 */
      getSystemDefaultPwd() {
        return this.$_getSystemData('default_pwd')?.configValue;
      },
    },
    methods: {
      /** @description: 처리구분 라디오 버튼 클릭 이벤트 */
      onChangedProcessType(e) {
        this.$set(this.config.processType, 'value', e.value.id);
        if (this.getProcessTypeId === 0) {
          this.$set(this.formData, 'changedPwdFl', true);
        } else {
          this.$set(this.formData, 'changedPwdFl', false);
        }
      },
      /** @description: 비밀번호 변경 유형 이벤트 */
      onChangedTempPwdFl(e) {
        this.formData.tempPwdFl = e.value;
        if (e.event && e.value === 2) {
          //1회용 임시 비밀번호
          this.formData.changedPwdFl = true;
          this.formData.isDisabledPwd = true;
          this.formData.loginPwd = this.getSystemDefaultPwd;
          this.formData.loginPwdConfirm = this.getSystemDefaultPwd;
        } else {
          //새 비밀번호
          this.formData.changedPwdFl = true;
          this.formData.isDisabledPwd = false;
          this.formData.loginPwd = '';
          this.formData.loginPwdConfirm = '';
        }
      },
      /** @description: 비밀번호 변경 유형 or 권한선택 툴팁 이벤트 */
      setTooltips(key) {
        this.config.isTooltip[key] = !this.config.isTooltip[key];
      },
      /** @description : 비밀번호 일치하는지 체크하는 이벤트 */
      passwordComparison() {
        return this.formData.loginPwd;
      },
      /** @description : 비밀번호 체크 메서드 */
      checkPassword(loginPwd, loginId, loginNm) {
        let hangulcheck = getRegexPattern('checkHangul');
        //시스템 설정값의 패스워드 정규식 체크
        if (!this.checkSystemPwdRegexp(loginPwd, loginId, loginNm)) {
          return false;
        } else if (loginPwd.search(/\s/) !== -1) {
          this.$_Msg(this.$_msgContents('PWD_NON_EMPTY'));
          return false;
        } else if (hangulcheck.test(loginPwd)) {
          this.$_Msg(this.$_msgContents('PWD_NON_KR'));
          return false;
        } else {
          return true;
        }
      },
      /** @description: 시스템 설정값의 패스워드 정규식 체크 */
      checkSystemPwdRegexp(loginPwd, loginId, loginNm) {
        let isCheck = true;

        //시스템 설정값의 패스워드 정규식 체크
        const checkValueOrErrMsg = this.$_validPwd(
          loginPwd,
          loginId,
          loginNm,
        );

        if (checkValueOrErrMsg === false) {
          isCheck = false;
        } else if (typeof checkValueOrErrMsg === 'string') {
          isCheck = false;
        }
        return isCheck;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountedData() {
        if (typeof String.prototype.parseFunction != 'function') {
          String.prototype.parseFunction = function () {
            const funcReg = /function *\(([^()]*)\)[ \n\t]*{(.*)}/gim;
            const match = funcReg.exec(this.replace(/\n/g, ' '));

            if (match) {
              return new Function(match[1].split(','), match[2]);
            }
            return null;
          };
        }

        this.$_eventbus.$on('ModalActiveMember:onSaveData', async (e, resolve) => {
          if (!e.validationGroup.validate().isValid) {
            return false;
          }

          let changedPwdFl = this.formData.changedPwdFl;

          //멤버 데이터 셋팅
          let members = this.contentData.selectedRowsData.map(d => {
            if (this.getProcessTypeId === 0 && changedPwdFl) {
              d.tempPwdFl = this.formData.tempPwdFl;
              d.loginPwd = this.formData.loginPwd;
            }

            return { ...d };
          });

          //비밀번호 validation 정규식 체크
          if (this.getProcessTypeId === 0) {
            for (let data of members) {
              let checkProcess = this.checkPassword(data.loginPwd, data.loginId, data.loginNm);
              if (!checkProcess) {
                return false;
              }
            }
          }

          if (
            await this.$_Confirm(
              this.$_msgContents('ACTIVATE_SELECTED_ACCOUNT', { defaultValue: '선택한 계정을 활성화하시겠습니까?' }),
            )
          ) {
            const payload = {
              actionname: 'MEMBER_STATE_ACTIVATION',
              data: {
                activeMembers: members.map(item => {
                  let password = '';

                  if (changedPwdFl) {
                    password = encryptPassword(item.loginId, item.loginPwd, this.$store.getters.getEncryptionType);
                  }
                  return {
                    memberId: item.id,
                    loginPwd: password,
                  };
                }),
              },
            };
            const res = await this.CALL_API(payload);
            if (isSuccess(res)) {
              this.$_Toast(
                this.$_msgContents('CHANGED_ACTIVE', { defaultValue: '선택한 계정을 활성 상태로 변경되었습니다.' }),
              );
              resolve(200);
            } else {
              this.$_Toast(res?.data?.header?.resMsg);
              resolve(500);
            }
          }
        });
      },
      /** @description : 라이프사이클 destroyed시 호출되는 메서드 */
      destroyedData() {
        this.$_eventbus.$off('ModalActiveMember:onSaveData');
      },
    },
    created() {},
    mounted() {
      this.mountedData();
    },
    destroyed() {
      this.destroyedData();
    },
  };
</script>

<style scoped>
  .contents-title-box {
    height: 40px;
    position: relative;
    border-bottom: 1px solid #ccc;
    line-height: 40px;
  }

  .contents-title-box .contents-title {
    height: 40px;
    padding-left: 5px;
    display: inline-block;
    font-size: 0.9em;
  }

  .contents-box {
    width: 100%;
    background-color: #fff;
  }

  .contents-box .table_form td {
    padding: 10px 0;
  }

  .conts-row:not(:last-child) {
    margin-bottom: 20px;
  }

  .conts-row .conts-box {
    display: inline-block;
  }

  .conts-row .conts-box > div {
    display: inline-block;
  }

  .conts-row .conts-box .conts-title {
    width: 120px;
    display: inline-block;
  }

  .conts-row .conts-box .conts-input {
    display: -webkit-inline-box;
  }

  .container-bottom-box {
    margin-top: 20px;
    font-size: 0.9em;
    text-align: center;
  }

  .container-bottom-box .length-txt {
    color: tomato;
  }
  #modal_menu_config_menuicon .dx-texteditor-input {
    font-size: 15px;
    padding-top: 0px;
    padding-left: 2px;
  }
</style>
